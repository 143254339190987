import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_order_getTrainsChangeOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsChangeOrderDetail' // 改签详情
import consumer_trains_order_changeOrderBooking from '@/lib/data-service/haolv-default/consumer_trains_order_changeOrderBooking' // 改签提交
import consumer_trains_order_changeToCheck from '@/lib/data-service/haolv-default/consumer_trains_order_changeToCheck' // 改签校验
// import CheckTravel from '@/component/checkTravel/index.vue'
import CheckTravel from '@/component/checkTravel/import_latest_version_module'
import moment from 'moment'
import trainSearch from './component/trainSearch/index.vue'
export default {
    data () {
        return {
            titleOffData: false,
            titleOffRule: true,
            handleBtnOff: null,
            formData: {},
            dialogVisible: false,
            loading: false,
            reasonRadio: '',
            orderNo: '',
            changeNo: '',
            orderInfo: {},
            params: {
                startTime: ''
            },
            rule: {
                startTime: [
                    {required: true, message: '请选择出发时间', trigger: 'change'}
                ]
            },
            pickerOptions: {
                disabledDate: this.disabledDate
            },
            changeTrainInfo: null,

            personalDialogVisible: false,
            evectionDialogVisible: false,
            changeResult: null, // 改签结果返回
            canSubmit: true,
            passengerSnsData:[], //存储选择乘客
        }

    },
    components: {
        CheckTravel,
        trainSearch
    },
    created () {

        /*if (this.$route.query.trainCode) {
            this.changeTrainInfo = {
                arriveDate: this.$route.query.arriveDate,
                daysTrip: this.$route.query.daysTrip,
                expressFee: this.$route.query.expressFee,
                fromStationCode: this.$route.query.fromStationCode,
                fromStationName: this.$route.query.fromStationName,
                fromTime: this.$route.query.fromTime,
                sIndex: parseInt(this.$route.query.sIndex),
                serviceCharge: this.$route.query.serviceCharge,
                spanTime: this.$route.query.spanTime,
                toStationCode: this.$route.query.toStationCode,
                toStationName: this.$route.query.toStationName,
                toTime: this.$route.query.toTime,
                trainCode: this.$route.query.trainCode,
                trainNo: this.$route.query.trainNo,
                trainSeatVoList: JSON.parse(this.$route.query.trainSeatVoList),
                trainStartDate: this.$route.query.trainStartDate
            }
        }*/
    },
    mounted () {
        this.changeTrainInfo = null;
    },
    activated () {
        this.orderNo = this.$route.query.orderNo
        this.changeNo = this.$route.query.changeNo ? this.$route.query.changeNo : ''
        this.getDetail()
        if (this.changeNo !== '') {
            this.getChangeDetail()
        }
        // this.initCheckTravelBox()
        this.changeTrainInfo = null;
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterPassengerType(val) {
            // 1:成人 2：儿童 3：学生票 4：残军票
            if (val === 1) {
                return '成人'
            } else if (val === 2) {
                return '儿童'
            } else if (val === 3) {
                return '学生票'
            } else if (val === 4) {
                return '残军票'
            } else {
                return ''
            }
        },
        filterPassengerCardType(val) {
            // 1：身份证2：儿童无证件3：港澳通行证4：台 湾通行证5：护照
            if (val === '1') {
                return '身份证'
            } else if (val === '2') {
                return '儿童无证件'
            } else if (val === '3') {
                return '港澳通行证'
            } else if (val === '4') {
                return '台湾通行证'
            } else if (val === '5') {
                return '护照'
            } else {
                return ''
            }
        },
        filterOrderStatus(val) {
            // 0:全部 1:-确认中 2- 待支付 3-待出票 4 -出票成功 5-出票失败 6-取消
            if (val === 1) {
                return '确认中'
            } else if (val === 2) {
                return '待支付'
            } else if (val === 3) {
                return '待出票'
            } else if (val === 4) {
                return '出票成功'
            } else if (val === 5) {
                return '出票失败'
            } else if (val === 6) {
                return '取消'
            } else {
                return ''
            }
        },
        gmtChangeStartFilter(val) {
            if (val == null || val == '') {
                return null
            } else {
                return moment(val).format('YYYY-MM-DD HH:mm')
            }
        },
        filterTicketStatus(val) {
            // 0：未出票，1：待出票，2：出票中，3：出票完成，4：出票失败,5:退票已申请,6:退票处理中,7:退票完成，8: 退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，13：改签已取消
            if (val === 0) {
                return '未出票'
            } else if (val === 1) {
                return '待出票'
            } else if (val === 2) {
                return '出票中'
            } else if (val === 3) {
                return '出票完成'
            } else if (val === 4) {
                return '出票失败'
            } else if (val === 5) {
                return '退票已申请'
            } else if (val === 6) {
                return '退票处理中'
            } else if (val === 7) {
                return '退票完成'
            } else if (val === 8) {
                return '退票失败'
            } else if (val === 9) {
                return '改签已申请'
            } else if (val === 10) {
                return '改签中'
            } else if (val === 11) {
                return '改签完成'
            } else if (val === 12) {
                return '改签失败'
            } else if (val === 13) {
                return '改签已取消'
            } else {
                return ''
            }
        },
    },
    methods: {
        clickTitleData(){
            this.titleOffData = !this.titleOffData
        },
        clickTitleRule(){
            this.titleOffRule = !this.titleOffRule
        },
        clickSpan(){
            this.$router.push({
                name: 'admin-my-order-train',
            })
        },
        selectablePassenger(data){
            if (data.ticketStatus == 3 || data.ticketStatus == 8) {
                return true
            } else {
                return false
            }
        },
        selectablePassengerTest(val, index) {
            let currentNum = 0
            let currentIndex = 0
            this.orderInfo.passengers.forEach((value, vIndex) => {
                if (value.passengerName === val.passengerName) {
                    currentNum++
                    currentIndex = vIndex // 记录的是最后一次同名的index
                }
            })

            if (val.ticketStatus === 3) { // 如果状态是出票完成
                if (currentNum === 1) { // 只有同一名字的一张单
                    this.handleBtnOff = true;
                    return true
                } else if (currentNum > 1 && (this.orderInfo.passengers[currentIndex].ticketStatus === 12 || this.orderInfo.passengers[currentIndex].ticketStatus === 13)) { // 同一名字下多张单
                    this.handleBtnOff = true;
                    return true
                } else {
                    this.handleBtnOff = false;
                     return false
                }
            } else {
                this.handleBtnOff = false;
                return false
            }
        },
        disabledDate(time) {
            let month = this.$moment().month()
            let day = 0
            if (month === 0 || month === 1) {
                day = 61
            } else if (month === 6) {
                day = 64
            } else {
                day = 63
            }
            return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
        },
        gmtStartFormatter(val) {
            return this.$moment(val.gmtStart).format('YYYY-MM-DD HH:mm')
        },
        gmtArriveFormatter(val) {
            return this.$moment(val.gmtArrive).format('YYYY-MM-DD HH:mm')
        },
        getDetail() {
            this.loading = true;
            consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {
                let orderInfo = res.datas;
                orderInfo.passengers.forEach(value => {
                    value.active = false;
                    value.passenger = [value]
                });
                this.orderInfo = orderInfo;
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getChangeDetail() {
            consumer_trains_order_getTrainsChangeOrderDetail({orderNo: this.changeNo}).then(res => {
                this.changeTrainInfo = res.datas
            })
        },
        getChangeDetailData(data) {
            this.dialogVisible = false;
            consumer_trains_order_getTrainsChangeOrderDetail({orderNo: data.changeNo}).then(res => {
                this.changeTrainInfo = res.datas
            })
        },
        checkEvection() {

        },
        handleBtn(index, row, col, event) {
            if (this.handleBtnOff) {
                row.flag = !row.flag;
                this.$refs[`passengerTable${index}`][0].toggleRowSelection(row,row.flag)
            }
        },
        //用户点击全选，清空全部选中
        handleSelectionAllChange(){
            this.$refs.passengerTable.clearSelection();
        },
        //用户选择单个
        handleSelectionChange(val) {
            this.passengerSnsData[0] = val[val.length-1];

            this.$refs.passengerTable.clearSelection();
            this.$refs.passengerTable.toggleRowSelection(val[val.length-1],true);
        },
        getSearchTrain(data){
            this.changeNo = data.changeNo;
            this.orderNo = data.orderNo;
            this.getChangeDetailData(data);
        },
        toSearchTrain() {
            this.dialogVisible = true;

            this.formData = {
                fromStationCode: this.orderInfo.fromStationCode,
                toStationCode: this.orderInfo.toStationCode,
                time: this.params.startTime,
                onlySearch: 0,
                orderNo: this.orderNo,
                changeNo: this.changeNo
            };
            setTimeout(() => {
                this.$refs.trainSearchRef.getDataAll()
            },10);


            // this.$router.push({
            //     name: 'admin-train-change-search',
            //     query: {
            //         fromStationCode: this.orderInfo.fromStationCode,
            //         toStationCode: this.orderInfo.toStationCode,
            //         time: this.params.startTime,
            //         onlySearch: 0,
            //         orderNo: this.orderNo,
            //         changeNo: this.changeNo
            //     }
            // })
        },
        initCheckTravelBox() {
            // this.$refs.checkTravelBox.check({
            //     businessType: 2, // 机票1，火车票2，酒店3
            //     businessParams: {
            //         evectionNo: this.evectionNo,
            //         // motorcycleType: this.currentTrainInfo.motorcycleType,
            //         // seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName,
            //     }
            // })
            this.$refs.checkTravelBox.init({
                checkFun: () => {
                    let userIds = []
                    this.orderInfo.passengers.forEach(value => {
                        if (value.active) {
                            userIds.push(value.userId)
                        }
                    })
                    let params = {
                        seatCode: this.changeTrainInfo.changeSeatCode,
                        seatName: this.changeTrainInfo.changeSeatName,
                        userIds
                    }
                    this.loading = true
                    return consumer_trains_order_changeToCheck(params).then(res => {
                        let violationResults = res.datas.violationResults
                        this.loading = false
                        return violationResults // 返回一个数组给组件
                        /* 数组每项包含以下内容
                        * businessType: 业务类型:1.火车票;2.机票;3.酒店 integer(int32)
                        * controlRules: 管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定,4不限 integer(int32)
                        * seatName: 差标政策 string
                        * standard: 差标类型 string
                        * userId: 违反用户id integer(int64)
                        * userRealName 违反用户名字 string
                        * violationOfReason 预订信息 string
                        * */
                    }).catch(() => {
                        this.canSubmit = true
                        this.loading = false
                        return null
                    })
                },
                reserveSubmitFun: (warningList) => { // 返回的warningList数组里面的每一项会多出一个reason的字段，是存储选中的超标原因
                    let passengerSns = []
                    let changeHeadCounts = 0
                    let changeAdultCounts = 0
                    let changeChildrenCounts = 0
                    this.orderInfo.passengers.forEach(value => {
                        if (value.active) {
                            passengerSns.push(value.sn)
                            changeHeadCounts++
                            if (value.passengerType === 2) {
                                changeChildrenCounts++
                            } else {
                                changeAdultCounts++
                            }
                        }
                    })
                    // 提交
                    let params = {
                        changeAdultCounts: changeAdultCounts, // 改签成人人数
                        changeChildrenCounts: changeChildrenCounts, // 改签儿童人数
                        changeHeadCounts: changeHeadCounts, // 改签总人数
                        orderNo: this.orderNo,
                        passengerSns: passengerSns, // 乘客sn数组
                        changeNo: this.changeNo,
                        overproofApplyList: warningList, // 超标人信息列表
                        reason: this.reasonRadio,
                        scheduledWay: this.orderInfo.scheduledWay
                    }
                    this.loading = true
                    return consumer_trains_order_changeOrderBooking(params).then(res => {
                        this.canSubmit = true
                        this.loading = false
                        return res.datas
                    }).catch(() => {
                        this.canSubmit = true
                        this.loading = false
                        return null
                    })
                },
                reserveResultFun: (result) => {
                    console.log(result)
                    if (!result) {
                        return
                    }
                    // 出差单行程的改签结果返回
                    this.changeResult = result
                    this.evectionDialogVisible = true
                }
            })
        },
        submit() {
            // 判断是否勾选了乘客
            let passengerSns = [];
            let changeHeadCounts = 0;
            let changeAdultCounts = 0;
            let changeChildrenCounts = 0;
            let userIds = [];

            if (this.passengerSnsData[0] == undefined) {
                this.$message({
                    type: 'warning',
                    message: '请先勾选改签的乘客'
                });
                return
            }
            this.passengerSnsData.forEach(value => {
                passengerSns.push(value.sn);
                userIds.push(value.userId);
                changeHeadCounts++;
                if (value.passengerType === 2) {
                    changeChildrenCounts++
                } else {
                    changeAdultCounts++
                }
            });

            // 判断是否选择了车次
            if (this.changeTrainInfo === null) {
                this.$message({
                    type: 'warning',
                    message: '请先选择车次'
                });
                return
            }
            if (this.reasonRadio === '') {
                this.$message({
                    type: 'warning',
                    message: '请先选择原因'
                });
                return
            }
            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false

            // 超标校验

            // if (this.orderInfo.scheduledWay === 2) {
            //     // 2: 出差单预定 1 :个人
            //     // this.$refs.checkTravelBox.run();
            //     debugger
            //     return
            // }

            // 提交
            let params = {
                changeAdultCounts: changeAdultCounts, // 改签成人人数
                changeChildrenCounts: changeChildrenCounts, // 改签儿童人数
                changeHeadCounts: changeHeadCounts, // 改签总人数
                orderNo: this.orderNo,
                passengerSns: passengerSns, // 乘客sn数组
                changeNo: this.changeNo,
                overproofApplyList: [], // 超标人信息列表
                reason: this.reasonRadio,
                scheduledWay: this.orderInfo.scheduledWay
            };
            consumer_trains_order_changeOrderBooking(params).then(res => {
                this.$message({
                    message: '改签成功',
                    type: 'success'
                });
                this.canSubmit = true;
                // 个人出差的改签结果返回
                this.changeResult = res.datas;
                this.personalDialogVisible = true;

                if (!(this.changeResult && this.changeResult.isPayment === 1)) {
                    this.personalDialogVisible = false;
                    this.$router.push({
                        name:'admin-my-order-train-change-list',
                    })
                }
            }).catch(() => {
                this.$message.error('改签失败');
                this.canSubmit = true
            })
        },
        goPay() {
            this.personalDialogVisible = false;
            //因公因私区分，跳向不同支付页面，因公----admin-pay,因私----admin-travel-payment
            //1-因私，2-因公
            if (this.orderInfo.scheduledWay === 1) {
                this.$router.push({
                    name: "admin-travel-payment",
                    query: {
                        orderNo: this.orderInfo.orderNo,
                        orderType: "2"
                    }
                });
            } else {
                this.$router.push({
                    name: 'admin-pay',
                    query: {
                        orderNos: this.changeResult.changeNo,
                        orderType: "2",// 为空字符是整个出差单，1是机票订单，2是火车票订单，3是酒店订单
                        pageType: 3,// 1是创建订单页，2是支付页，3是改签页
                    }
                });
            }
        },
        goMyOrderList() {
            this.personalDialogVisible = false
            this.$router.push({
                name: 'admin-my-order-train',
            })
        },
        goMyChangeOrderList() {
            this.evectionDialogVisible = false
            this.$router.push({
                name: 'admin-my-order-train-change-list',
            })
        },
        closeToPayDialog(done) {
            this.getDetail()
            if (this.changeNo !== '') {
                this.getChangeDetail()
            }
            // this.initCheckTravelBox()

            done()
        },
        back() {
            this.$router.back();
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
